<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Anderson Miller</h1>

          <p>
            Anderson was selected by the Kansas City Royals in the 3rd round
            (98th overall pick) of the 2015 MLB Draft. In 2021, Anderson was
            invited to Big League Camp with Kansas City Royals and then assigned
            to the Omaha Storm Chasers (AAA). In 2023, Anderson signed with the
            Los Angeles Dodgers.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-card max-width="300" max-height="300" hover>
            <v-img
              class="align-center"
              max-width="300"
              max-height="300"
              :src="AndersonProfileCard"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="8">
          <h2>Train with Anderson</h2>

          <p>
            Anderson works with all ages and skill levels, who are ready to
            improve their game.
          </p>

          <v-btn
            href="https://amillerlessons.as.me/"
            target="_blank"
            color="primary"
            small
          >
            Schedule
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h2>Unfinished Podcast with Anderson Miller</h2>
          <p>
            Former professional baseball player Anderson Miller hosts
            Unfinished. A platform for amazing guests & Anderson to have
            dialogue about life, the lessons learned along the way, and how they
            shape us into who we are. This podcast provides a unique
            perspective, from inside the sports world looking out & not the
            outside looking in. Unfinished is a place to have real
            conversations, provide honesty, advice, & inspire.
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card
            max-width="300"
            max-height="300"
            hover
            target="_blank"
            href="https://linktr.ee/podunfinished"
          >
            <v-img
              class="align-center"
              max-width="300"
              max-height="300"
              :src="AndersonMillerPodcast"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-center">Check out Anderson's Youtube Channel</h3>

          <v-tabs v-model="tab" background-color="transparent" grow show-arrows>
            <v-tab v-for="player in unfinished.players" :key="player">
              {{ player }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(video, index) in unfinished.videos"
              :key="index"
            >
              <v-card flat>
                <v-card-text>
                  <div class="iframe-container">
                    <iframe
                      :src="video"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AndersonProfileCard from '@/assets/images/AndersonProfileCard.png'
import AndersonMillerPodcast from '@/assets/images/AndersonMillerPodcast.jpeg'
import PDTrain from '@/assets/images/PDTrain.svg'

export default {
  data() {
    return {
      AndersonProfileCard: AndersonProfileCard,
      AndersonMillerPodcast: AndersonMillerPodcast,
      PDTrain: PDTrain,
      unfinished: {
        players: ['Hunter Greene', 'Zion Rose', 'Dillon Head'],
        videos: [
          'https://www.youtube.com/embed/G5CferCWFqM',
          'https://www.youtube.com/embed/H5-ghEkuy20',
          'https://www.youtube.com/embed/agQNgn0QuFY',
        ],
      },
      tab: null,
    }
  },
}
</script>

<style>
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
